import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { WppDivider, WppIconDrag } from '@platform-ui-kit/components-library-react'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateCollectionFormElementValue } from 'pages/collections/common/createCollectionSideModal/formUtils'
import { ProductType } from 'types/products/enums'
import { Truncate } from 'ui-base/truncate/Truncate'

import * as S from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/productRow/ProductRow.styled'

interface Props {
  element: CreateCollectionFormElementValue
  isLastRow: boolean
  handleRemoveVersion: (versionId: string) => void
}

export const SelectedProductRow = ({ element, isLastRow, handleRemoveVersion }: Props) => {
  const { t } = useTranslation(['common'])
  const { shortDescription, productType, logoUrl, productName, versionId, versionName } = element
  const isNoCodeProduct = productType === ProductType.NO_CODE_APPLICATION

  const { attributes, isDragging, listeners, setNodeRef, transform, transition, isSorting } = useSortable({
    id: element.versionId!,
    animateLayoutChanges: args => {
      const { isSorting, wasDragging } = args

      return (!isSorting && !wasDragging) || defaultAnimateLayoutChanges(args)
    },
  })

  const hasTransition = !!transition
  const hasTransform = !!transform

  return (
    <>
      <S.ProductRowContent
        gap={8}
        align="center"
        justify="between"
        data-testid="selected-product-row"
        {...attributes}
        {...(isDragging && { 'data-is-dragging': true })}
        ref={setNodeRef}
        withDragNDrop
        style={
          {
            ...(isSorting && {
              transform:
                'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1))scaleY(var(--scale-y, 1))',
            }),
            ...(hasTransition && { '--transition': transition }),
            ...(hasTransform && {
              '--translate-x': `${Math.round(transform.x)}px`,
              '--translate-y': `${Math.round(transform.y)}px`,
              '--scale-x': `${transform.scaleX}`,
              '--scale-y': `${transform.scaleY}`,
            }),
            ...((hasTransition || hasTransform) && {
              '--will-change': [hasTransition && 'transition', hasTransform && 'transform'].filter(Boolean).join(','),
            }),
          } as CSSProperties
        }
      >
        <S.DragHandle {...listeners}>
          <WppIconDrag />
        </S.DragHandle>
        <S.ProductLogo src={logoUrl} size="s" variant="square" data-testid="product-logo" />

        <S.NameWrapper direction="column" grow={1}>
          <Truncate type="s-body">
            {productName} {!isNoCodeProduct && ` - ${versionName}`}
          </Truncate>
          <S.Description type="s-body">{shortDescription || '-'}</S.Description>
        </S.NameWrapper>
        <S.ActionButton onClick={() => handleRemoveVersion(versionId)} data-testid="remove-version-button">
          {t('common|remove')}
        </S.ActionButton>
      </S.ProductRowContent>

      {!isLastRow && <WppDivider />}
    </>
  )
}
